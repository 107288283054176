import React, { useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { useSelector, useDispatch } from 'react-redux';

import '../routes/session-player/styles/index.scss';
import '../public/vendors/flag/sprite-flags-24x24.css';
import '../public/vendors/material-design-iconic-font/css/material-design-iconic-font.min.css';
import '../public/vendors/animate.css';
import '../public/vendors/bootstrap-rtl.css';
import 'react-notifications/lib/notifications.css';
import '../styles/bootstrap.scss';
import '../styles/app.scss';
import '../styles/app-rtl.scss';
import 'rc-slider/assets/index.css';
import 'react-daterange-picker/dist/css/react-calendar.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-intl-tel-input/dist/main.css';
import { wrapper } from '../redux/store/index';
import 'setimmediate';
import GeneralMessage from '../app/components/GeneralMessage';
import { ToastContainer } from 'react-toastify';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { getSettings } from '../utils/session';

// pages/_app.js
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_POSTHOG_KEY) {
  // checks that we are client-side
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://eu.i.posthog.com',
    person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug(); // debug mode in development
    },
  });
}

const App = ({ Component, pageProps }) => {
  // Check host at the app level
  if (typeof window !== 'undefined' && window.location.host === '57.128.160.21') {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '24px',
          color: '#333',
          fontFamily: 'sans-serif',
          padding: '20px',
          textAlign: 'center',
          background: '#f5f5f5',
        }}
      >
        App is up and running v.1.0.0
      </div>
    );
  }

  const dispatch = useDispatch();
  const { userInfo } = useSelector(({ auth }) => auth);
  const [settings, setSettings] = useState({});

  useEffect(() => {
    if (typeof window != 'undefined') {
      setSettings(getSettings());
    }
  }, []);

  const router = useRouter();

  useEffect(() => {
    let found;
    if (Object.keys(userInfo).length > 0) {
      const websites = userInfo?.websites;
      const workspace = router.query.workspace;

      found = websites?.some((obj) => obj._id === workspace);
    }
    const allowedPages = [
      'billing',
      'upgrade',
      'setup-tracking-script',
      'affiliate',
      'verify-email',
      'integration-connected',
    ];
    if (!found && Object.keys(userInfo).length > 0 && !allowedPages?.includes(router.asPath?.split('/')[1])) {
      if ('websites' in userInfo && userInfo.websites.length > 0) {
        const currentWebsiteId = localStorage.getItem('currentWebsiteId');
        const currentWebsiteUrl = localStorage.getItem('currentWebsiteUrl');
        const newWebId = userInfo?.defaultWorkspace ?? userInfo?.websites[0]?._id;
        if (!currentWebsiteUrl) {
          if (userInfo.websites) {
            localStorage.setItem('currentWebsiteUrl', userInfo.websites[0].validationUrl);
            localStorage.setItem('currentWebsiteId', newWebId);
          }
        }

        if (currentWebsiteId || userInfo.websites) {
          const useWebsiteId = currentWebsiteId ? currentWebsiteId : newWebId;
          const url = router?.asPath;
          const newUrl = url?.replace(/\/([^/]+)\/([^/?]+)/, `/$1/${useWebsiteId}`);
          router.push(newUrl);
          typeof window !== 'undefined' && localStorage.setItem('currentWebsiteId', useWebsiteId);
        }
      }
    }
  }, [dispatch, userInfo, userInfo.websites, router.query.workspace]);

  useEffect(() => {
    if (userInfo.websites) {
      const currentWebsiteUrl = localStorage.getItem('currentWebsiteUrl');
      if (router.query.workspace) {
        const currentWebsite = userInfo.websites.filter((website) => website._id === router.query.workspace);
        if (currentWebsite.length > 0) {
          localStorage.setItem('currentWebsiteUrl', currentWebsite[0].validationUrl);
          localStorage.setItem('currentWebsiteId', currentWebsite[0]._id);
        }
      } else {
        if (!currentWebsiteUrl) {
          if (userInfo.websites) {
            localStorage.setItem('currentWebsiteUrl', userInfo.websites[0].validationUrl);
            localStorage.setItem('currentWebsiteId', userInfo.websites[0]._id);
          }
        }
      }
    }
  }, [userInfo.websites]);
  /* INTEGRATIONS SCRIPT LOAD END */

  return (
    <React.Fragment>
      {settings['app.domain'] && (
        <>
          <Script
            id={settings['app.domain']}
            strategy="afterInteractive"
            src={
              settings['app.domain'] == 'happierleads.com'
                ? '/scripts/happierleadsScript.js'
                : '/scripts/otherScript.js'
            }
          />
        </>
      )}
      {/* {settings['app.domain'] == 'happierleads.com' && (
     
      )} */}

      <Script
        strategy="beforeInteractive"
        src="https://code.jquery.com/jquery-3.2.1.slim.min.js"
        integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
        crossOrigin="anonymous"
      />
      <Script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
        crossOrigin="anonymous"
        strategy="beforeInteractive"
      />

      <Head>
        <title> {settings['app.name']} Admin Dashboard</title>
        <link rel="shortcut icon" href={settings['app.logo.image']} />
      </Head>

      <GeneralMessage />
      <ToastContainer />
      <PostHogProvider instance={posthog}>
        <Component {...pageProps} />
      </PostHogProvider>
    </React.Fragment>
  );
};

export default wrapper.withRedux(App);
