import {
  ADD_NEW_WEBSITES_REQUEST,
  ADD_NEW_WEBSITE_ERROR,
  ADD_NEW_WEBSITE_SUCCESS,
  CLEAR_MESSAGES_WEBSITES,
  DELETE_WEBSITE_REQUEST,
  WEBSITES_UPDATE_REQUEST,
  SET_WEBSITE_SETTINGS,
  UPDATE_WEBSITE_INFORMATION,
  WEBSITES_UPDATE_EMAIL_DIGEST_FREQUENCY,
  ADD_BLACKLIST_WEBSITE,
  FETCH_BLACKLIST_WEBSITES,
  FETCH_BLACKLIST_WEBSITES_ERROR,
  FETCH_BLACKLIST_WEBSITES_SUCCESS,
  SHOW_WORKSPACE_MODAL,
  RENAME_WORKSPACE_REQUEST,
  RENAME_WORKSPACE_ERROR,
  RENAME_WORKSPACE_SUCCESS,
  RENAME_WORKSPACE_MODAL,
  ADD_NEW_EXCLUDED_PAGES_REQUEST,
  ADD_NEW_EXCLUDED_PAGES_SUCCESS,
  ADD_NEW_EXCLUDED_PAGES_ERROR,
  ADD_FAVORITE_WEBSITE_SUCCESS,
  ADD_FAVORITE_WEBSITE_REQUEST,
  REMOVE_FAVORITE_WEBSITE_REQUEST,
  REMOVE_FAVORITE_WEBSITE_SUCCESS,
  REDEEM_CODE_REQUEST,
  REDEEM_CODE_SUCCESS,
  REDEEM_CODE_ERROR,
  WEBSITES_UPDATE_SUCCESS,
  WEBSITES_UPDATE_ERROR,
  DELETE_WEBSITE_SUCCESS,
  DELETE_WEBSITE_ERROR,
  CLEAR_WEBSITES_UPDATE_SUCCESS,
  UPDATE_WEBSITE_INFORMATION_SUCCESS,
  UPDATE_WEBSITE_INFORMATION_ERROR,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  loader: false,
  success: false,
  message: '',
  error: false,
  deleteWebsiteId: '',
  deleteWebsiteLoading: false,
  deleteWebsiteSuccess: false,
  deleteWebsiteError: false,
  successMsg: '',
  index: '',
  websiteId: '',
  blacklisted: {},
  blacklistError: null,
  newWorkspaceMode: false,
  updateWebsiteSuccess: false,
  updateWebsiteLoading: false,
  updateWebsiteError: false,
  updateWebsiteErrorMessage: '',
  updateWebsiteInformationLoading: false,
  updateWebsiteInformationSuccess: false,
  updateWebsiteInformationError: false,
  newWebsiteId: '',
};

const Websites = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DELETE_WEBSITE_REQUEST: {
      return {
        ...state,
        deleteWebsiteId: action.payload,
        deleteWebsiteLoading: true,
      };
    }
    case DELETE_WEBSITE_SUCCESS: {
      return {
        ...state,
        deleteWebsiteId: action.payload,
        deleteWebsiteLoading: false,
        deleteWebsiteSuccess: true,
        successMsg: action.payload,
      };
    }
    case DELETE_WEBSITE_ERROR: {
      return {
        ...state,
        deleteWebsiteId: action.payload,
        deleteWebsiteLoading: false,
        deleteWebsiteError: true,
      };
    }

    case ADD_NEW_WEBSITES_REQUEST: {
      return {
        ...state,
        loader: true,
      };
    }

    case ADD_NEW_WEBSITE_SUCCESS: {
      return {
        ...state,
        message: action.payload,
        newWebsiteId: action.payload,
        success: true,
        loader: false,
      };
    }
    case ADD_FAVORITE_WEBSITE_REQUEST: {
      return {
        ...state,
        loader: true,
      };
    }

    case ADD_FAVORITE_WEBSITE_SUCCESS: {
      return {
        ...state,
        message: action.payload,
        success: true,
        loader: false,
      };
    }
    case REMOVE_FAVORITE_WEBSITE_REQUEST: {
      return {
        ...state,
        loader: true,
      };
    }

    case REMOVE_FAVORITE_WEBSITE_SUCCESS: {
      return {
        ...state,
        message: action.payload,
        success: true,
        loader: false,
      };
    }

    case SHOW_WORKSPACE_MODAL: {
      return {
        ...state,
        newWorkspaceMode: action.payload,
      };
    }

    case ADD_NEW_WEBSITE_ERROR: {
      return {
        ...state,
        message: action.payload,
        error: true,
        loader: false,
      };
    }

    case CLEAR_MESSAGES_WEBSITES: {
      return {
        ...state,
        loader: false,
        success: false,
        error: false,
        message: '',
        updateWebsiteSuccess: false,
        updateWebsiteLoading: false,
        updateWebsiteError: false,
        deleteWebsiteSuccess: false,
        deleteWebsiteLoading: false,
        deleteWebsiteError: false,
        updateWebsiteErrorMessage: '',
        successMsg: '',
      };
    }

    case WEBSITES_UPDATE_REQUEST: {
      return {
        ...state,
        updateWebsiteLoading: true,
      };
    }
    case WEBSITES_UPDATE_SUCCESS: {
      return {
        ...state,
        updateWebsiteLoading: false,
        updateWebsiteSuccess: true,
      };
    }
    case WEBSITES_UPDATE_ERROR: {
      return {
        ...state,
        updateWebsiteLoading: false,
        updateWebsiteError: true,
        updateWebsiteErrorMessage: action.payload,
      };
    }

    case UPDATE_WEBSITE_INFORMATION: {
      return {
        ...state,
        updateWebsiteInformationLoading: true,
      };
    }
    case UPDATE_WEBSITE_INFORMATION_SUCCESS: {
      return {
        ...state,
        updateWebsiteInformationLoading: false,
        updateWebsiteInformationSuccess: true,
      };
    }
    case UPDATE_WEBSITE_INFORMATION_ERROR: {
      return {
        ...state,
        updateWebsiteInformationLoading: false,
        updateWebsiteInformationError: true,
      };
    }

    case WEBSITES_UPDATE_EMAIL_DIGEST_FREQUENCY: {
      return {
        ...state,
      };
    }

    case SET_WEBSITE_SETTINGS: {
      return {
        ...state,
        index: action.payload.index,
        websiteId: action.payload.websiteId,
        blacklisted: {},
      };
    }

    case ADD_BLACKLIST_WEBSITE: {
      return {
        ...state,
      };
    }

    case FETCH_BLACKLIST_WEBSITES: {
      return {
        ...state,
      };
    }

    case FETCH_BLACKLIST_WEBSITES_ERROR: {
      return {
        ...state,
        blacklistError: action.payload,
      };
    }

    case FETCH_BLACKLIST_WEBSITES_SUCCESS: {
      return {
        ...state,
        blacklisted: action.payload,
      };
    }

    case RENAME_WORKSPACE_REQUEST: {
      return {
        ...state,
        loader: true,
      };
    }

    case RENAME_WORKSPACE_SUCCESS: {
      return {
        ...state,
        message: action.payload,
        success: true,
        loader: false,
      };
    }

    case RENAME_WORKSPACE_MODAL: {
      return {
        ...state,
        renameWorkspaceMode: action.payload,
      };
    }

    case RENAME_WORKSPACE_ERROR: {
      return {
        ...state,
        message: action.payload,
        error: true,
        loader: false,
      };
    }

    case ADD_NEW_EXCLUDED_PAGES_REQUEST: {
      return {
        ...state,
        loader: true,
        success: false,
      };
    }

    case ADD_NEW_EXCLUDED_PAGES_SUCCESS: {
      return {
        ...state,
        message: action.payload,
        success: true,
        loader: false,
      };
    }

    case ADD_NEW_EXCLUDED_PAGES_ERROR: {
      return {
        ...state,
        message: action.payload,
        error: true,
        loader: false,
      };
    }

    case REDEEM_CODE_REQUEST: {
      return {
        ...state,
        loader: true,
        success: false,
      };
    }

    case REDEEM_CODE_SUCCESS: {
      return {
        ...state,
        message: action.payload,
        success: true,
        loader: false,
      };
    }

    case REDEEM_CODE_ERROR: {
      return {
        ...state,
        message: action.payload,
        error: true,
        loader: false,
      };
    }
    case CLEAR_WEBSITES_UPDATE_SUCCESS: {
      return {
        ...state,
        message: action.payload,
        error: false,
        loader: false,
        updateWebsiteLoading: false,
        updateWebsiteSuccess: false,
        updateWebsiteInformationLoading: false,
        updateWebsiteInformationSuccess: false,
        updateWebsiteInformationError: false,
      };
    }

    default:
      return state;
  }
};

export default Websites;
