// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const GET_TRANSLATION = 'GET_TRANSLATION';
export const GET_TRANSLATION_SUCCESS = 'GET_TRANSLATION_SUCCESS';
export const CHANGE_DIRECTION = 'change-direction';
export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const ON_SHOW_LOADER = 'on_show_loader';

//Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNUP_USER_WHITELABEL = 'SIGNUP_USER_WHITELABEL';
export const SIGNUP_USER_WHITELABEL_SUCCESS = 'SIGNUP_USER_WHITELABEL_SUCCESS';
export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR';
export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';
export const RESET_VERIFIED_WEBSITE = 'RESET_VERIFIED_WEBSITE';
export const SHOW_WORKSPACE_MODAL = 'SHOW_WORKSPACE_MODAL';
export const SEND_RECOVERY_EMAIL = 'SEND_RECOVERY_EMAIL';
export const SUCCESS_RECOVERY_EMAIL = 'SUCCESS_RECOVERY_EMAIL';
export const SEND_RESET_PASSWORD = 'SEND_RESET_PASSWORD';
export const SUCCESS_RESET_PASSWORD = 'SUCCESS_RESET_PASSWORD';
export const MOVE_USER_TO_HOME = 'MOVE_USER_TO_HOME';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const SET_WEBSITEURL_SETUPSCRIPT = 'SET_WEBSITEURL_SETUPSCRIPT';
export const CHECK_TRIAL = 'CHECK_TRIAL';
export const RENAME_WORKSPACE_MODAL = 'RENAME_WORKSPACE_MODAL';

//LEADS
export const CLEAR_ALL_STATES_LEADS = 'CLEAR_ALL_STATES_LEADS';
export const USER_HAPPIER_LEADS = 'user_happier_leads';
export const USER_DETAILS_HAPPIER_LEADS = 'user_details_happier_leads';
export const ADD_FILTER_HAPPIER_LEADS = 'add_filter_happier_leads';
export const FETCH_ALL_FILTERS_HAPPIERLEADS = 'fetch_all_filters_happierleads';
export const CALL_FETCH_ALL_FILTERS_HAPPIERLEADS = 'call_fetch_all_filters_happierleads';
export const GET_LEADS_DATA = 'get_leads_data';
export const DELETE_LEADS_REQUEST = 'DELETE_LEADS_REQUEST';
export const DELETE_LEADS_SUCCESS = 'DELETE_LEADS_SUCCESS';
export const DELETE_LEADS_ERROR = 'DELETE_LEADS_ERROR';
export const GET_START_AND_END_DATE = 'get_start_and_end_date';
export const LOADING_LEADS_TABLE = 'loading_leads_table';
export const ERROR = 'ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const DELETE_SAVED_FITER = 'DELETE_SAVED_FITER';
export const CREATE_SEARCHABLE_LEADS_DATA = 'CREATE_SEARCHABLE_LEADS_DATA';
export const UPDATE_DISPLAY_LEADS_DATA = 'UPDATE_DISPLAY_LEADS_DATA';
export const GET_FILTERED_LEADS_DATA = 'GET_FILTERED_LEADS_DATA';
export const GET_INFO_OF_CURRENT_ROW_ON_LEADS_TABLE = 'GET_INFO_OF_CURRENT_ROW_ON_LEADS_TABLE';
export const GET_ORIGIN_HAPPIERLEARS = 'GET_ORIGIN_HAPPIERLEARS';
export const REMOVE_FILTER_HAPPIERLEAD_LEADS = 'REMOVE_FILTER_HAPPIERLEAD_LEADS';
export const UPDATE_FILTER_HAPPIERLEAD_LEADS = 'UPDATE_FILTER_HAPPIERLEAD_LEADS';
export const SET_NOTFOUND = 'SET_NOTFOUND';
export const CANCEL_NOTFOUND = 'CANCEL_NOTFOUND';
export const ADD_LEADS_TAG = 'ADD_LEADS_TAG';
export const DELETE_LEADS_TAG = 'DELETE_LEADS_TAG';
export const MODIFY_LEADS_TAG = 'MODIFY_LEADS_TAG';
export const SUCCESS_DELETE_TAG = 'SUCCESS_LEADS_TAG';
export const SUCCESS_MODIFY_TAG = 'SUCCESS_MODIFY_TAG';
export const GET_TAGS_LEAD = 'GET_TAGS_LEAD';
export const SUCCESS_GET_TAGS_LEADS = 'SUCCESS_GET_TAGS_LEADS';
export const RESET_LEADS_TAGS = 'RESET_LEADS_TAGS';
export const CONTROL_RELOAD_SIDEBAR_LEADS = 'CONTROL_RELOAD_SIDEBAR_LEADS';
export const CONTROL_RESET_SIDEBAR_LEADS = 'CONTROL_RESET_SIDEBAR_LEADS';
export const UPDATE_EXISTING_TAGS = 'UPDATE_EXISTING_TAGS';
export const UPDATE_CURRENT_LEADS_PAGE = 'UPDATE_CURRENT_LEADS_PAGE';
export const UPDATE_COUNT_LEADS = 'UPDATE_COUNT_LEADS';
export const REFRESH_LEADS_PAGE = 'REFRESH_LEADS_PAGE';
export const CREATE_SELECTED_COLUMNS_LEADS = 'UPDATE_SELECTED_COLUMNS_LEADS';
export const CREATE_SELECTED_COLUMNS_LEADS_SUCCESS = 'UPDATE_SELECTED_COLUMNS_LEADS_SUCCESS';
export const CREATE_SELECTED_COLUMNS_LEADS_ERROR = 'UPDATE_SELECTED_COLUMNS_LEADS_ERROR';
export const UNLOCK_LEADS = 'UNLOCK_LEADS';
export const SUCCESS_UNLOCK_LEADS = ' SUCCESS_UNLOCK_LEADS';

//ONBOARDING
export const CALL_WEBSITE_URL_HTTP = 'CALL_WESITE_URL_HTTP';
export const CALL_WEBSITE_URL_HTTPS = 'CALL_WEBSETE_URL_HTTPS';
export const LOADING_ONBOARDING = 'LOADING_ONBOARDING';
export const POST_ONBOARDING_DETAILS = 'POST_ONBOARDING_DETAILS';
export const ONBOARDING_SUCCESS = 'ONBOARDING_SUCCESS';
export const ONBOARDING_FAILED = 'ONBOARDING_FAILED';
export const CLEAR_ONBOARDING_ERRORS = 'CLEAR_ONBOARDING_ERRORS';
export const SET_SUCCESS_ONBOARDING_FALSE = 'SET_SUCCESS_ONBOARDING_FALSE';
export const SEND_DEVELOPER_MAIL = 'SEND_DEVELOPER_MAIL';
export const SEND_DEVELOPER_MAIL_SUCCESS = 'SEND_DEVELOPER_MAIL_SUCCESS';
export const SEND_DEVELOPER_MAIL_CLOSE = 'SEND_DEVELOPER_MAIL_CLOSE';
export const SEND_ONBOARDING_CREDENTIALS = 'SEND_ONBOARDING_CREDENTIALS';
export const SEND_ONBOARDING_CREDENTIALS_SUCCESS = 'SEND_ONBOARDING_CREDENTIALS_SUCCESS';
export const SEND_ONBOARDING_CREDENTIALS_CLOSE = 'SEND_ONBOARDING_CREDENTIALS_CLOSE';
export const UPDATE_ACCOUNT_STATUS = 'UPDATE_ACCOUNT_STATUS';

// LEAD
export const LEAD_FETCH_DATA_REQUEST = 'LEAD_FETCH_DATA_REQUEST';
export const LEAD_FETCH_DATA_SUCCESS = 'LEAD_FETCH_DATA_SUCCESS';
export const LEAD_FETCH_DATA_ERROR = 'LEAD_FETCH_DATA_ERROR';
export const WEBSITE_CLIENT_ID = 'WEBSITE_CLIENT_ID';
export const CLEAR_ALL_STATES_LEAD = 'CLEAR_ALL_STATES_LEAD';
export const ADD_TAGS_LEAD_PROFILE = 'ADD_TAGS_LEAD_PROFILE';
export const MODIFY_TAGS_LEAD_PROFILE = 'MODIFY_TAGS_LEAD_PROFILE';
export const SUCCESS_MODIFY_TAGS_LEAD_PROFILE = 'SUCCESS_MODIFY_TAGS_LEAD_PROFILE';
export const DELETE_TAGS_LEAD_PROFILE = 'DELETE_TAGS_LEAD_PROFILE';
export const SUCCESS_DELETE_TAGS_LEAD_PROFILE = 'SUCCESS_DELETE_TAGS_LEAD_PROFILE';
export const SET_WEBSITE_ID_TAGS_PROFILE = 'SET_WEBSITE_ID_TAGS_PROFILE';
export const SET_LEADS_QUERY_STRING = 'SET_LEADS_QUERY_STRING';
export const HIDE_LEAD_PROFILE = 'HIDE_LEAD_PROFILE';
export const HIDE_LEAD_PROFILE_SUCCESS = 'HIDE_LEAD_PROFILE_SUCCESS';
export const HIDE_LEAD_PROFILE_FAILED = 'HIDE_LEAD_PROFILE_FAILED';
export const GET_LEAD_AI_SUMMARY = 'GET_LEAD_AI_SUMMARY';
export const GET_LEAD_AI_SUMMARY_SUCCESS = 'GET_LEAD_AI_SUMMARY_SUCCESS';
export const GET_LEAD_AI_SUMMARY_FAILED = 'GET_LEAD_AI_SUMMARY_FAILED';

// ENRICH
export const ENRICH_FETCH_DATA_REQUEST = 'ENRICH_FETCH_DATA_REQUEST';
export const ENRICH_FETCH_DATA_SUCCESS = 'ENRICH_FETCH_DATA_SUCCESS';
export const ENRICH_FETCH_DATA_ERROR = 'ENRICH_FETCH_DATA_ERROR';
export const ENRICH_CLEAR_MESSAGES = 'ENRICH_CLEAR_MESSAGES';
export const VERIFY_EMAIL_ENRICH = 'VERIFY_EMAIL_ENRICH';
export const SUCCESS_VERIFY_EMAIL_ENRICH = 'SUCCESS_VERIFY_EMAIL_ENRICH';
export const VERIFY_EMAIL_ENRICH_POST = 'VERIFY_EMAIL_ENRICH_POST';
export const SUCCESS_VERIFY_EMAIL_ENRICH_POST = 'SUCCESS_VERIFY_EMAIL_ENRICH_POST';
export const ERROR_VERIFY_EMAIL_ENRICH_POST = 'ERROR_VERIFY_EMAIL_ENRICH_POST';
export const CLEAR_ENRICH = 'CLEAR_ENRICH';

// WEBHOOK
export const WEBHOOK_UPDATE_REQUEST = 'WEBHOOK_UPADTE_REQUEST';
export const WEBHOOK_UPDATE_SUCCESS = 'WEBHOOK_UPDATE_SUCCESS';
export const WEBHOOK_UPDATE_ERROR = 'WEBHOOK_UPDATE_ERROR';
export const SET_WEBHOOK_URL = 'SET_WEBHOOK_URL';
export const WEBHOOK_CLEAR_MESSAGES = 'WEBHOOK_CLEAR_MESSAGES';
export const PERMIT_SETUP_SCRIPTS = 'PERMIT_SETUP_SCRIPTS';
export const REJECT_SETUP_SCRIPTS = 'REJECT_SETUP_SCRIPTS';
export const SEND_TEST_URL = 'SEND_TEST_URL';
export const SET_WEBHOOK_WEBSITE_ID = 'SET_WEBHOOK_WEBSITE_ID';
export const SUCCESS_SEND_TEST_WEBHOOK = 'SUCCESS_SEND_TEST_WEBHOOK';
export const CLEAR_SEND_TEST_WEBHOOK = 'CLEAR_SEND_TEST_WEBHOOK';

//EXPORT CSV
export const DOWNLOAD_CSV = 'DOWNLOAD_CSV';
export const ERROR_DOWNLOAD_CSV = 'ERROR_DOWNLOAD_CSV';
export const SUCCESS_DOWNLOAD_CSV = 'SUCCESS_DOWNLOAD_CSV';
export const CLEAR_EXPORT_CSV_ERROR = 'CLEAR_EXPORT_CSV_ERROR';
export const SET_CSV_GET_PARAMS = 'SET_CSV_GET_PARAMS';

// INVITE
export const INVITE_MEMBERS_REQUEST = 'INVITE_MEMBERS_REQUEST';
export const INVITE_MEMBERS_SUCCESS = 'INVITE_MEMBERS_SUCCESS';
export const INVITE_MEMBERS_ERROR = 'INVITE_MEMBERS_ERROR';
export const INVITE_MEMBERS_CLEAR_MESSAGES = 'INVITE_MEMBERS_CLEAR_MESSAGES';

// SELECTED WEBSITE
export const SET_SELECTED_WEBSITE = 'SET_SELECTED_WEBSITE';

// WEBSITES
export const ADD_NEW_WEBSITES_REQUEST = 'ADD_NEW_WEBSITES_REQUEST';
export const ADD_NEW_WEBSITE_ERROR = 'ADD_NEW_WEBSITE_ERROR';
export const ADD_NEW_WEBSITE_SUCCESS = 'ADD_NEW_WEBSITE_SUCCESS';
export const CLEAR_MESSAGES_WEBSITES = 'CLEAR_MESSAGES_WEBSITES';
export const DELETE_WEBSITE_REQUEST = 'DELETE_WEBSITE_REQUEST';
export const DELETE_WEBSITE_SUCCESS = 'DELETE_WEBSITE_SUCCESS';
export const DELETE_WEBSITE_ERROR = 'DELETE_WEBSITE_ERROR';
export const WEBSITES_UPDATE_REQUEST = 'WEBSITES_UPDATE_REQUEST';
export const WEBSITES_UPDATE_SUCCESS = 'WEBSITES_UPDATE_SUCCESS';
export const CLEAR_WEBSITES_UPDATE_SUCCESS = 'CLEAR_WEBSITES_UPDATE_SUCCESS';
export const WEBSITES_UPDATE_ERROR = 'WEBSITES_UPDATE_ERROR';
export const UPDATE_WEBSITE_INFORMATION = 'UPDATE_WEBSITE_INFORMATION';
export const UPDATE_WEBSITE_INFORMATION_SUCCESS = 'UPDATE_WEBSITE_INFORMATION_SUCCESS';
export const UPDATE_WEBSITE_INFORMATION_ERROR = 'UPDATE_WEBSITE_INFORMATION_ERROR';
export const WEBSITES_UPDATE_EMAIL_DIGEST_FREQUENCY = 'WEBSITES_UPDATE_EMAIL_DIGEST_FREQUENCY';
export const SET_WEBSITE_SETTINGS = 'SET_WEBSITE_SETTINGS';
export const ADD_BLACKLIST_WEBSITE = 'ADD_BLACKLIST_WEBSITE';
export const FETCH_BLACKLIST_WEBSITES = 'FETCH_BLACKLIST_WEBSITES';
export const FETCH_BLACKLIST_WEBSITES_SUCCESS = 'FETCH_BLACKLIST_WEBSITES_SUCCESS';
export const FETCH_BLACKLIST_WEBSITES_ERROR = 'FETCH_BLACKLIST_WEBSITES_ERROR';
export const RENAME_WORKSPACE_REQUEST = 'RENAME_WORKSPACE_REQUEST';
export const RENAME_WORKSPACE_ERROR = 'RENAME_WORKSPACE_ERROR';
export const RENAME_WORKSPACE_SUCCESS = 'RENAME_WORKSPACE_SUCCESS';
export const ADD_NEW_EXCLUDED_PAGES_REQUEST = 'ADD_NEW_EXCLUDED_PAGES_REQUEST';
export const ADD_NEW_EXCLUDED_PAGES_SUCCESS = 'ADD_NEW_EXCLUDED_PAGES_SUCCESS';
export const ADD_NEW_EXCLUDED_PAGES_ERROR = 'ADD_NEW_EXCLUDED_PAGES_ERROR';
export const ADD_FAVORITE_WEBSITE_REQUEST = 'ADD_FAVORITE_WEBSITE_REQUEST';
export const ADD_FAVORITE_WEBSITE_ERROR = 'ADD_FAVORITE_WEBSITE_ERROR';
export const ADD_FAVORITE_WEBSITE_SUCCESS = 'ADD_FAVORITE_WEBSITE_SUCCESS';
export const REMOVE_FAVORITE_WEBSITE_REQUEST = 'REMOVE_FAVORITE_WEBSITE_REQUEST';
export const REMOVE_FAVORITE_WEBSITE_ERROR = 'REMOVE_FAVORITE_WEBSITE_ERROR';
export const REMOVE_FAVORITE_WEBSITE_SUCCESS = 'REMOVE_FAVORITE_WEBSITE_SUCCESS';
export const REDEEM_CODE_REQUEST = 'REDEEM_CODE_REQUEST';
export const REDEEM_CODE_SUCCESS = 'REDEEM_CODE_SUCCESS';
export const REDEEM_CODE_ERROR = 'REDEEM_CODE_ERROR';
export const UPDATE_WEBSITE_LIMIT_REQUEST = 'UPDATE_WEBSITE_LIMIT_REQUEST';
export const UPDATE_WEBSITE_LIMIT_SUCCESS = 'UPDATE_WEBSITE_LIMIT_SUCCESS';
export const UPDATE_WEBSITE_LIMIT_ERROR = 'UPDATE_WEBSITE_LIMIT_ERROR';

// MEMBERS
export const GET_MEMBERS = 'GET_MEMBERS';
export const UPDATE_MEMBER_ROLE = 'UPDATE_MEMBER_ROLE';
export const GET_MEMBER_SUCCESS = 'GET_MEMBER_SUCCESS';
export const ACCESS_MEMBERS_PAGE = 'ACCESS_MEMBERS_PAGE';
export const REJECT_MEMBERS_PAGE = 'REJECT_MEMBERS_PAGE';
export const MEMBERS_ERROR = 'MEMBERS_ERROR';
export const MEMBERS_CLEAR_MESSAGES = 'MEMBERS_CLEAR_MESSAGES';
export const DELETE_MEMBER = 'DELETE_MEMBER';
export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
export const UPDATE_WEBSITES_MEMBERS = 'UPDATE_WEBSITES_MEMBERS';

// UTILITIES
export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';

export const POST_RATING_REQUEST = 'POST_RATING';
export const POST_RATING_SUCCESS = 'POST_RATING_SUCCESS';
export const POST_RATING_ERROR = 'POST_RATING_ERROR';

export const SET_PERSONALIZED_DATA = 'SET_PERSONALIZED_DATA';
export const SET_PERSONALIZED_DATA_SUCCESS = 'SET_PERSONALIZED_DATA_SUCCESS';
export const SET_PERSONALIZED_DATA_ERROR = 'SET_PERSONALIZED_DATA_SUCCESS';

// TAGS
export const GET_TAGS = 'GET_TAGS';
export const MODIFY_TAGS = 'MODIFY_TAGS';
export const ADD_TAGS = 'ADD_TAGS';
export const ADD_TAGS_SUCCESS = 'ADD_TAGS_SUCCESS';
export const MODIFY_TAGS_SUCCESS = 'MODIFY_TAGS_SUCCESS';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const CLEAR_TAG_MESSAGES = 'CLEAR_TAG_MESSAGES';
export const TAG_REQUEST_ERROR = 'TAG_REQUEST_ERROR';
export const DELETE_TAGS = 'DELETE_TAGS';
export const DELETE_TAGS_SUCCESS = 'DELETE_TAGS_SUCCESS';

//GENERAL_ERROR_SUCCESS
export const GENERAL_MESSAGE_SUCCESS = 'GENERAL_MESSAGE_SUCCESS';
export const GENERAL_MESSAGE_ERROR = 'GENERAL_MESSAGE_ERROR';
export const GENERAL_MESSAGE_CLEAR = 'GENERAL_MESSAGE_CLEAR';

//Comments
export const GET_COMMENTS = 'GET_COMMENTS';
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const MODIFY_COMMENT = 'MODIFY_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';

//Session Player
export const GET_RECORDED_SESSION = 'GET_RECORDED_SESSION';
export const GET_RECORDED_SESSION_SUCCESSFUL = 'GET_RECORDED_SESSION_SUCCESSFUL';
export const GET_RECORDED_SESSION_FAILED = 'GET_RECORDED_SESSION_FAILED';

// EDIT PROFILE
export const EDIT_PROFILE_REQUEST = 'EDIT_PROFILE_REQUEST';
export const EDIT_PROFILE_ERROR = 'EDIT_PROFILE_ERROR';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PASSWORD_REQUEST = 'EDIT_PASSWORD_REQUEST';
export const EDIT_PASSWORD_ERROR = 'EDIT_PASSWORD_ERROR';
export const EDIT_PASSWORD_SUCCESS = 'EDIT_PASSWORD_SUCCESS';

// BLACKLIST
export const FORWARD_CSV = 'FORWARD_CSV';
export const FORWARD_CSV_ERROR = 'FORWARD_CSV_ERROR';
export const FORWARD_CSV_SUCCESS = 'FORWARD_CSV_SUCCESS';
export const CLEAR_FORWARD_CSV = 'CLEAR_FORWARD_CSV';
export const FORWARD_CSV_EMAIL = 'FORWARD_CSV_EMAIL';
export const FORWARD_CSV_EMAIL_ERROR = 'FORWARD_CSV_EMAIL_ERROR';
export const FORWARD_CSV_EMAIL_SUCCESS = 'FORWARD_CSV_EMAIL_SUCCESS';
export const CLEAR_FORWARD_CSV_EMAIL = 'CLEAR_FORWARD_CSV_EMAIL';
export const GET_CSV_BLACKLIST = 'GET_CSV_BLACKLIST';
export const GET_CSV_BLACKLIST_ERROR = 'GET_CSV_BLACKLIST_ERROR';
export const GET_CSV_BLACKLIST_SUCCESS = 'GET_CSV_BLACKLIST_SUCCESS';
export const DELETE_CSV_BLACKLIST = 'DELETE_CSV_BLACKLIST';
export const DELETE_CSV_BLACKLIST_ERROR = 'DELETE_CSV_BLACKLIST_ERROR';
export const DELETE_CSV_BLACKLIST_SUCCESS = 'DELETE_CSV_BLACKLIST_SUCCESS';
export const GET_EMAIL_CSV_BLACKLIST = 'GET_EMAIL_CSV_BLACKLIST';
export const GET_EMAIL_CSV_BLACKLIST_ERROR = 'GET_EMAIL_CSV_BLACKLIST_ERROR';
export const GET_EMAIL_CSV_BLACKLIST_SUCCESS = 'GET_EMAIL_CSV_BLACKLIST_SUCCESS';
export const DELETE_EMAIL_CSV_BLACKLIST = 'DELETE_EMAIL_CSV_BLACKLIST';
export const DELETE_EMAIL_CSV_BLACKLIST_ERROR = 'DELETE_EMAIL_CSV_BLACKLIST_ERROR';
export const DELETE_EMAIL_CSV_BLACKLIST_SUCCESS = 'DELETE_EMAIL_CSV_BLACKLIST_SUCCESS';

// HUBSPOT
export const GET_HUBSPOT_OWNERS = 'GET_HUBSPOT_OWNERS';
export const GET_HUBSPOT_OWNERS_SUCCESS = 'GET_HUBSPOT_OWNERS_SUCCESS';
export const GET_HUBSPOT_OWNERS_ERROR = 'GET_HUBSPOT_OWNERS_ERROR';
export const SAVE_NEW_COMPANY = 'SAVE_NEW_COMPANY';
export const SAVE_NEW_COMPANY_SUCCESS = 'SAVE_NEW_COMPANY_SUCCESS';
export const SAVE_NEW_COMPANY_ERROR = 'SAVE_NEW_COMPANY_ERROR';
export const SEARCH_COMPANY = 'SEARCH_COMPANY';
export const SEARCH_COMPANY_SUCCESS = 'SEARCH_COMPANY_SUCCESS';
export const SEARCH_COMPANY_ERROR = 'SEARCH_COMPANY_ERROR';
export const CONNECT_CONTACTS = 'CONNECT_CONTACTS';
export const CONNECT_CONTACTS_SUCCESS = 'CONNECT_CONTACTS_SUCCESS';
export const CONNECT_CONTACTS_ERROR = 'CONNECT_CONTACTS_ERROR';
export const CONNECT_EXISTING_COMPANY = 'CONNECT_EXISTING_COMPANY';
export const CONNECT_EXISTING_COMPANY_SUCCESS = 'CONNECT_EXISTING_COMPANY_SUCCESS';
export const CONNECT_EXISTING_COMPANY_ERROR = 'CONNECT_EXISTING_COMPANY_ERROR';
export const CLEAR_CONTACTS_ERROR = 'CLEAR_CONTACTS_ERROR';

// SALESFORCE
export const GET_SALESFORCE_OWNERS = 'GET_SALESFORCE_OWNERS';
export const GET_SALESFORCE_OWNERS_SUCCESS = 'GET_SALESFORCE_OWNERS_SUCCESS';
export const GET_SALESFORCE_OWNERS_ERROR = 'GET_SALESFORCE_OWNERS_ERROR';

export const SAVE_NEW_SALESFORCE_COMPANY = 'SAVE_NEW_SALESFORCE_COMPANY';
export const SAVE_NEW_SALESFORCE_COMPANY_SUCCESS = 'SAVE_NEW_SALESFORCE_COMPANY_SUCCESS';
export const SAVE_NEW_SALESFORCE_COMPANY_ERROR = 'SAVE_NEW_SALESFORCE_COMPANY_ERROR';

export const SEARCH_SALESFORCE_COMPANY = 'SEARCH_SALESFORCE_COMPANY';
export const SEARCH_SALESFORCE_COMPANY_SUCCESS = 'SEARCH_SALESFORCE_COMPANY_SUCCESS';
export const SEARCH_SALESFORCE_COMPANY_ERROR = 'SEARCH_SALESFORCE_COMPANY_ERROR';

export const CONNECT_SALESFORCE_CONTACTS = 'CONNECT_SALESFORCE_CONTACTS';
export const CONNECT_SALESFORCE_CONTACTS_SUCCESS = 'CONNECT_SALESFORCE_CONTACTS_SUCCESS';
export const CONNECT_SALESFORCE_CONTACTS_ERROR = 'CONNECT_SALESFORCE_CONTACTS_ERROR';

export const CONNECT_EXISTING_SALESFORCE_COMPANY = 'CONNECT_EXISTING_SALESFORCE_COMPANY';
export const CONNECT_EXISTING_SALESFORCE_COMPANY_SUCCESS = 'CONNECT_EXISTING_SALESFORCE_COMPANY_SUCCESS';
export const CONNECT_EXISTING_SALESFORCE_COMPANY_ERROR = 'CONNECT_EXISTING_SALESFORCE_COMPANY_ERROR';

export const CLEAR_SALESFORCE_CONTACTS_ERROR = 'CLEAR_SALESFORCE_CONTACTS_ERROR';

// ZOHO
export const GET_ZOHO_OWNERS = 'GET_ZOHO_OWNERS';
export const GET_ZOHO_OWNERS_SUCCESS = 'GET_ZOHO_OWNERS_SUCCESS';
export const GET_ZOHO_OWNERS_ERROR = 'GET_ZOHO_OWNERS_ERROR';
export const SAVE_NEW_ZOHO_COMPANY = 'SAVE_NEW_ZOHO_COMPANY';
export const SAVE_NEW_ZOHO_COMPANY_SUCCESS = 'SAVE_NEW_ZOHO_COMPANY_SUCCESS';
export const SAVE_NEW_ZOHO_COMPANY_ERROR = 'SAVE_NEW_ZOHO_COMPANY_ERROR';
export const SEARCH_ZOHO_COMPANY = 'SEARCH_ZOHO_COMPANY';
export const SEARCH_ZOHO_COMPANY_SUCCESS = 'SEARCH_ZOHO_COMPANY_SUCCESS';
export const SEARCH_ZOHO_COMPANY_ERROR = 'SEARCH_ZOHO_COMPANY_ERROR';
export const CONNECT_ZOHO_CONTACTS = 'CONNECT_ZOHO_CONTACTS';
export const CONNECT_ZOHO_CONTACTS_SUCCESS = 'CONNECT_ZOHO_CONTACTS_SUCCESS';
export const CONNECT_ZOHO_CONTACTS_ERROR = 'CONNECT_ZOHO_CONTACTS_ERROR';
export const CONNECT_EXISTING_ZOHO_COMPANY = 'CONNECT_EXISTING_ZOHO_COMPANY';
export const CONNECT_EXISTING_ZOHO_COMPANY_SUCCESS = 'CONNECT_EXISTING_ZOHO_COMPANY_SUCCESS';
export const CONNECT_EXISTING_ZOHO_COMPANY_ERROR = 'CONNECT_EXISTING_ZOHO_COMPANY_ERROR';

export const CLEAR_ZOHO_CONTACTS_ERROR = 'CLEAR_ZOHO_CONTACTS_ERROR';

// SLACK
export const GET_SLACK_CHANNELS = 'GET_SLACK_CHANNELS';
export const GET_SLACK_CHANNELS_SUCCESS = 'GET_SLACK_CHANNELS_SUCCESS';
export const GET_SLACK_CHANNELS_ERROR = 'GET_SLACK_CHANNELS_ERROR';
// SLACK

// pipedrive
export const GET_PIPEDRIVE_OWNERS = 'GET_PIPEDRIVE_OWNERS';
export const GET_PIPEDRIVE_OWNERS_SUCCESS = 'GET_PIPEDRIVE_OWNERS_SUCCESS';
export const GET_PIPEDRIVE_OWNERS_ERROR = 'GET_PIPEDRIVE_OWNERS_ERROR';
export const SAVE_NEW_PIPEDRIVE_COMPANY = 'SAVE_NEW_PIPEDRIVE_COMPANY';
export const SAVE_NEW_PIPEDRIVE_COMPANY_SUCCESS = 'SAVE_NEW_PIPEDRIVE_COMPANY_SUCCESS';
export const SAVE_NEW_PIPEDRIVE_COMPANY_ERROR = 'SAVE_NEW_PIPEDRIVE_COMPANY_ERROR';
export const SEARCH_PIPEDRIVE_COMPANY = 'SEARCH_PIPEDRIVE_COMPANY';
export const SEARCH_PIPEDRIVE_COMPANY_SUCCESS = 'SEARCH_PIPEDRIVE_COMPANY_SUCCESS';
export const SEARCH_PIPEDRIVE_COMPANY_ERROR = 'SEARCH_PIPEDRIVE_COMPANY_ERROR';
export const CONNECT_EXISTING_PIPEDRIVE_COMPANY = 'CONNECT_EXISTING_PIPEDRIVE_COMPANY';
export const CONNECT_EXISTING_PIPEDRIVE_COMPANY_SUCCESS = 'CONNECT_EXISTING_PIPEDRIVE_COMPANY_SUCCESS';
export const CONNECT_EXISTING_PIPEDRIVE_COMPANY_ERROR = 'CONNECT_EXISTING_PIPEDRIVE_COMPANY_ERROR';
export const CONNECT_PIPEDRIVE_CONTACTS = 'CONNECT_PIPEDRIVE_CONTACTS';
export const CONNECT_PIPEDRIVE_CONTACTS_SUCCESS = 'CONNECT_PIPEDRIVE_CONTACTS_SUCCESS';
export const CONNECT_PIPEDRIVE_CONTACTS_ERROR = 'CONNECT_PIPEDRIVE_CONTACTS_ERROR';
export const CLEAR_PIPEDRIVE_CONTACTS_ERROR = 'CLEAR_PIPEDRIVE_CONTACTS_ERROR';

// MANAGE_SERVICES
export const MANAGE_SERVICES_REQUEST = 'MANAGE_SERVICES_REQUEST';
export const MANAGE_SERVICES_SUCCESS = 'MANAGE_SERVICES_SUCCESS';
export const MANAGE_SERVICES_ERROR = 'MANAGE_SERVICES_ERROR';
export const CLEAR_MANAGE_SERVICES = 'CLEAR_MANAGE_SERVICES';

export const ADD_CONTACT = 'ADD_CONTACT';
export const ADD_CONTACT_SUCCESS = 'ADD_CONTACT_SUCCESS';
export const ADD_CONTACT_ERROR = 'ADD_CONTACT_ERROR';
export const CLEAR_CONTACT_ERROR = 'CLEAR_CONTACT_ERROR';

// ICP
export const SAVE_ICP_FILTERS = 'SAVE_ICP_FILTERS';
export const SAVE_ICP_FILTERS_SUCCESS = 'SAVE_ICP_FILTERS_SUCCESS';
export const SAVE_ICP_FILTERS_ERROR = 'SAVE_ICP_FILTERS_ERROR';
export const GET_ICP_FILTERS = 'GET_ICP_FILTERS';
export const GET_ICP_FILTERS_SUCCESS = 'GET_ICP_FILTERS_SUCCESS';
export const GET_ICP_FILTERS_ERROR = 'GET_ICP_FILTERS_ERROR';

// LIST
export const SAVE_LIST = 'SAVE_LIST';
export const SAVE_LIST_SUCCESS = 'SAVE_LIST_SUCCESS';
export const SAVE_LIST_ERROR = 'SAVE_LIST_ERROR';
export const CLEAR_CREATE_LIST_ERROR = 'CLEAR_CREATE_LIST_ERROR';

export const GET_LIST = 'GET_LIST';
export const GET_LIST_SUCCESS = 'GET_LIST_SUCCESS';
export const GET_LIST_ERROR = 'GET_LIST_ERROR';

export const EXPORT_LIST = 'EXPORT_LIST';
export const EXPORT_LIST_SUCCESS = 'EXPORT_LIST_SUCCESS';
export const EXPORT_LIST_ERROR = 'EXPORT_LIST_ERROR';
export const CLEAR_EXPORT_LIST_ERROR = 'CLEAR_EXPORT_LIST_ERROR';

export const IMPORT_LIST = 'IMPORT_LIST';
export const IMPORT_LIST_SUCCESS = 'IMPORT_LIST_SUCCESS';
export const IMPORT_LIST_ERROR = 'IMPORT_LIST_ERROR';
export const CLEAR_IMPORT_LIST_ERROR = 'CLEAR_IMPORT_LIST_ERROR';

export const DELETE_LIST = 'DELETE_LIST';
export const DELETE_LIST_SUCCESS = 'DELETE_LIST_SUCCESS';
export const DELETE_LIST_ERROR = 'DELETE_LIST_ERROR';

export const DELETE_LIST_CONTACTS_REQUEST = 'DELETE_LIST_CONTACTS_REQUEST';
export const DELETE_LIST_CONTACTS_SUCCESS = 'DELETE_LIST_CONTACTS_SUCCESS';
export const DELETE_LIST_CONTACTS_ERROR = 'DELETE_LIST_CONTACTS_ERROR';

export const UPDATE_LIST = 'UPDATE_LIST';
export const UPDATE_LIST_SUCCESS = 'UPDATE_LIST_SUCCESS';
export const UPDATE_LIST_ERROR = 'UPDATE_LIST_ERROR';

export const UPDATE_LIST_CONTACT = 'UPDATE_LIST_CONTACT';
export const UPDATE_LIST_CONTACT_SUCCESS = 'UPDATE_LIST_CONTACT_SUCCESS';
export const UPDATE_LIST_CONTACT_ERROR = 'UPDATE_LIST_CONTACT_ERROR';

export const GET_CONTACT_INSIGHT_INFO = 'GET_CONTACT_INSIGHT_INFO';
export const GET_CONTACT_INSIGHT_INFO_SUCCESS = 'GET_CONTACT_INSIGHT_INFO_SUCCESS';
export const GET_CONTACT_INSIGHT_INFO_ERROR = 'GET_CONTACT_INSIGHT_INFO_ERROR';

export const GET_LISTS_STATS = 'GET_LISTS_STATS';
export const GET_LISTS_STATS_SUCCESS = 'GET_LISTS_STATS_SUCCESS';
export const GET_LISTS = 'GET_LISTS';
export const GET_LISTS_SUCCESS = 'GET_LISTS_SUCCESS';
export const GET_LISTS_ERROR = 'GET_LISTS_ERROR';

// LEAD_SCORING_MODEL
export const SAVE_LEAD_SCORING_MODEL = 'SAVE_LEAD_SCORING_MODEL';
export const SAVE_LEAD_SCORING_MODEL_SUCCESS = 'SAVE_LEAD_SCORING_MODEL_SUCCESS';
export const SAVE_LEAD_SCORING_MODEL_ERROR = 'SAVE_LEAD_SCORING_MODEL_ERROR';
export const CLEAR_CREATE_LEAD_SCORING_MODEL_ERROR = 'CLEAR_CREATE_LEAD_SCORING_MODEL_ERROR';

// LEAD_SCORING_MODEL_JOB_TITLE
export const SAVE_LEAD_JOB_TITLE_SCORING_MODEL = 'SAVE_LEAD_JOB_TITLE_SCORING_MODEL';
export const SAVE_LEAD_JOB_TITLE_SCORING_MODEL_SUCCESS = 'SAVE_LEAD_JOB_TITLE_SCORING_MODEL_SUCCESS';
export const SAVE_LEAD_JOB_TITLE_SCORING_MODEL_ERROR = 'SAVE_LEAD_JOB_TITLE_SCORING_MODEL_ERROR';

export const GET_LEAD_SCORING_MODEL = 'GET_LEAD_SCORING_MODEL';
export const GET_LEAD_SCORING_MODEL_SUCCESS = 'GET_LEAD_SCORING_MODEL_SUCCESS';
export const GET_LEAD_SCORING_MODEL_ERROR = 'GET_LEAD_SCORING_MODEL_ERROR';

export const DELETE_LEAD_SCORING_MODEL = 'DELETE_LEAD_SCORING_MODEL';
export const DELETE_LEAD_SCORING_MODEL_SUCCESS = 'DELETE_LEAD_SCORING_MODEL_SUCCESS';
export const DELETE_LEAD_SCORING_MODEL_ERROR = 'DELETE_LEAD_SCORING_MODEL_ERROR';

export const UPDATE_LEAD_SCORING_MODEL = 'UPDATE_LEAD_SCORING_MODEL';
export const UPDATE_LEAD_SCORING_MODEL_SUCCESS = 'UPDATE_LEAD_SCORING_MODEL_SUCCESS';
export const UPDATE_LEAD_SCORING_MODEL_ERROR = 'UPDATE_LEAD_SCORING_MODEL_ERROR';

export const GET_LEAD_SCORING_MODELS = 'GET_LEAD_SCORING_MODELS';
export const GET_LEAD_SCORING_MODELS_SUCCESS = 'GET_LEAD_SCORING_MODELS_SUCCESS';
export const GET_LEAD_SCORING_MODELS_ERROR = 'GET_LEAD_SCORING_MODELS_ERROR';

// PROSPECTOR
export const GET_PROSPECTOR = 'GET_PROSPECTOR';
export const GET_PROSPECTOR_SUCCESS = 'GET_PROSPECTOR_SUCCESS';
export const GET_PROSPECTOR_ERROR = 'GET_PROSPECTOR_ERROR';
export const GET_PEOPLE_PROSPECTOR = 'GET_PEOPLE_PROSPECTOR';
export const GET_PEOPLE_PROSPECTOR_SUCCESS = 'GET_PEOPLE_PROSPECTOR_SUCCESS';
export const GET_PEOPLE_PROSPECTOR_ERROR = 'GET_PEOPLE_PROSPECTOR_ERROR';
export const UPDATE_CURRENT_PEOPLE_PROSPECTOR_PAGE = 'UPDATE_CURRENT_PEOPLE_PROSPECTOR_PAGE';

export const SELECTED_PROSPECTOR = 'SELECTED_PROSPECTOR';
export const SELECTED_LIST_PROSPECTOR = 'SELECTED_LIST_PROSPECTOR';
export const UPDATE_CURRENT_PROSPECTOR_PAGE = 'UPDATE_CURRENT_PROSPECTOR_PAGE';
export const GET_FILTERED_PROSPECTOR_DATA = 'GET_FILTERED_PROSPECTOR_DATA';
export const CREATE_SEARCHABLE_PROSPECTOR_DATA = 'CREATE_SEARCHABLE_PROSPECTOR_DATA';
export const SWITCH_PROSPECTOR_TABS = 'SWITCH_PROSPECTOR_TABS';
export const GET_PROSPECTOR_COMPANY_INSIGHTS = 'GET_PROSPECTOR_COMPANY_INSIGHTS';
export const GET_PROSPECTOR_COMPANY_INSIGHTS_SUCCESS = 'GET_PROSPECTOR_COMPANY_INSIGHTS_SUCCESS';
export const GET_PROSPECTOR_COMPANY_INSIGHTS_ERROR = 'GET_PROSPECTOR_COMPANY_INSIGHTS_ERROR';

export const GET_PROSPECTOR_STATUS = 'GET_PROSPECTOR_STATUS';
export const GET_PROSPECTOR_STATUS_SUCCESS = 'GET_PROSPECTOR_STATUS_SUCCESS';
export const GET_PROSPECTOR_STATUS_ERROR = 'GET_PROSPECTOR_STATUS_ERROR';

export const CLEAR_PROSPECTOR_ERROR = 'CLEAR_PROSPECTOR_ERROR';
export const CLEAR_PROSPECTOR_ERROR_SUCCESS = 'CLEAR_PROSPECTOR_ERROR_SUCCESS';
export const CLEAR_PROSPECTOR_ERROR_ERROR = 'CLEAR_PROSPECTOR_ERROR_ERROR';

export const CREATE_PROSPECTOR_FILTER = 'CREATE_PROSPECTOR_FILTER';
export const CREATE_PROSPECTOR_FILTER_SUCCESS = 'CREATE_PROSPECTOR_FILTER_SUCCESS';
export const CREATE_PROSPECTOR_FILTER_ERROR = 'CREATE_PROSPECTOR_FILTER_ERROR';

export const GET_PROSPECTOR_FILTERS = 'GET_PROSPECTOR_FILTERS';
export const GET_PROSPECTOR_FILTERS_SUCCESS = 'GET_PROSPECTOR_FILTERS_SUCCESS';
export const GET_PROSPECTOR_FILTERS_ERROR = 'GET_PROSPECTOR_FILTERS_ERROR';

export const DELETE_PROSPECTOR_FILTER = 'DELETE_PROSPECTOR_FILTER';
export const DELETE_PROSPECTOR_FILTER_SUCCESS = 'DELETE_PROSPECTOR_FILTER_SUCCESS';
export const DELETE_PROSPECTOR_FILTER_ERROR = 'DELETE_PROSPECTOR_FILTER_ERROR';

//PROSPECTS
export const UPDATE_PROSPECTS_PAGE = 'UPDATE_PROSPECTS_PAGE';
export const GET_PROSPECTS = 'GET_PROSPECTS';
export const GET_PROSPECTS_SUCCESS = 'GET_PROSPECTS_SUCCESS';
export const GET_PROSPECTS_ERROR = 'GET_PROSPECTS_ERROR';

export const GET_PROSPECT_INFO = 'GET_PROSPECT_INFO';
export const GET_PROSPECT_INFO_SUCCESS = 'GET_PROSPECT_INFO_SUCCESS';
export const GET_PROSPECT_INFO_ERROR = 'GET_PROSPECT_INFO_ERROR';

export const GET_PROSPECT_MESSAGE = 'GET_PROSPECT_MESSAGE';
export const GET_PROSPECT_MESSAGE_SUCCESS = 'GET_PROSPECT_MESSAGE_SUCCESS';
export const GET_PROSPECT_MESSAGE_ERROR = 'GET_PROSPECT_MESSAGE_ERROR';

export const UPDATE_PROSPECT_MESSAGE = 'UPDATE_PROSPECT_MESSAGE';
export const UPDATE_PROSPECT_MESSAGE_SUCCESS = 'UPDATE_PROSPECT_MESSAGE_SUCCESS';
export const UPDATE_PROSPECT_MESSAGE_ERROR = 'UPDATE_PROSPECT_MESSAGE_ERROR';
export const UPDATE_PROSPECT_MESSAGE_LOADING = 'UPDATE_PROSPECT_MESSAGE_LOADING';

export const UPDATE_PROSPECT_INFO = 'UPDATE_PROSPECT_INFO';
export const UPDATE_PROSPECT_INFO_SUCCESS = 'UPDATE_PROSPECT_INFO_SUCCESS';
export const UPDATE_PROSPECT_INFO_ERROR = 'UPDATE_PROSPECT_INFO_ERROR';

export const STOP_PROSPECTS = 'STOP_PROSPECTS';
export const STOP_PROSPECTS_SUCCESS = 'STOP_PROSPECTS_SUCCESS';
export const STOP_PROSPECTS_ERROR = 'STOP_PROSPECTS_ERROR';
export const CLEAR_PROSPECTS_ERROR = 'CLEAR_PROSPECTS_ERROR';

export const RESCHEDULE_PROSPECTS = 'RESCHEDULE_PROSPECTS';
export const RESCHEDULE_PROSPECTS_SUCCESS = 'RESCHEDULE_PROSPECTS_SUCCESS';
export const RESCHEDULE_PROSPECTS_ERROR = 'RESCHEDULE_PROSPECTS_ERROR';
export const SCHEDULE_SEND_NOW = 'SCHEDULE_SEND_NOW';
export const SCHEDULE_SEND_NOW_SUCCESS = 'SCHEDULE_SEND_NOW_SUCCESS';
export const SCHEDULE_SEND_NOW_ERROR = 'SCHEDULE_SEND_NOW_ERROR';
export const DELETE_EMAIL_PROSPECTS = 'DELETE_EMAIL_PROSPECTS';
export const DELETE_EMAIL_PROSPECTS_SUCCESS = 'DELETE_EMAIL_PROSPECTS_SUCCESS';
export const DELETE_EMAIL_PROSPECTS_ERROR = 'DELETE_EMAIL_PROSPECTS_ERROR';

export const GET_PROSPECTS_COUNT = 'GET_PROSPECTS_COUNT';
export const GET_PROSPECTS_COUNT_SUCCESS = 'GET_PROSPECTS_COUNT_SUCCESS';
export const GET_PROSPECTS_COUNT_ERROR = 'GET_PROSPECTS_COUNT_ERROR';

export const CHANGE_EMAIL_ACCOUNT = 'CHANGE_EMAIL_ACCOUNT';
export const CHANGE_EMAIL_ACCOUNT_SUCCESS = 'CHANGE_EMAIL_ACCOUNT_SUCCESS';
export const CHANGE_EMAIL_ACCOUNT_ERROR = 'CHANGE_EMAIL_ACCOUNT_ERROR';

export const DELETE_MY_ACCOUNT = 'DELETE_MY_ACCOUNT';
export const DELETE_MY_ACCOUNT_SUCCESS = 'DELETE_MY_ACCOUNT_SUCCESS';
export const DELETE_MY_ACCOUNT_ERROR = 'DELETE_MY_ACCOUNT_ERROR';

export const SCHEDULE_SEND_TIME = 'SCHEDULE_SEND_TIME';
export const SCHEDULE_SEND_TIME_SUCCESS = 'SCHEDULE_SEND_TIME_SUCCESS';
export const SCHEDULE_SEND_TIME_ERROR = 'SCHEDULE_SEND_TIME_ERROR';

//EXPORT PROSPECTOR CSV
export const DOWNLOAD_PROSPECTOR_CSV = 'DOWNLOAD_PROSPECTOR_CSV';
export const DOWNLOAD_PROSPECTOR_CSV_SUCCESS = 'DOWNLOAD_PROSPECTOR_CSV_SUCCESS';
export const DOWNLOAD_PROSPECTOR_CSV_ERROR = 'DOWNLOAD_PROSPECTOR_CSV_ERROR';
export const CLEAR_PROSPECTOR_CSV_ERROR = 'CLEAR_PROSPECTOR_CSV_ERROR';
export const SET_PROSPECTOR_CSV_PARAMS = 'SET_PROSPECTOR_CSV_PARAMS';

// AUTOMATION
export const CREATE_AUTOMATION = 'CREATE_AUTOMATION';
export const CREATE_AUTOMATION_SUCCESS = 'CREATE_AUTOMATION_SUCCESS';
export const CREATE_AUTOMATION_ERROR = 'CREATE_AUTOMATION_ERROR';
export const GET_AUTOMATIONS = 'GET_AUTOMATIONS';
export const GET_AUTOMATIONS_SUCCESS = 'GET_AUTOMATIONS_SUCCESS';
export const GET_AUTOMATIONS_ERROR = 'GET_AUTOMATIONS_ERROR';
export const GET_AUTOMATIONS_HISTORY = 'GET_AUTOMATIONS_HISTORY';
export const GET_AUTOMATIONS_HISTORY_SUCCESS = 'GET_AUTOMATIONS_HISTORY_SUCCESS';
export const GET_AUTOMATIONS_HISTORY_ERROR = 'GET_AUTOMATIONS_HISTORY_ERROR';
export const DISABLE_AUTOMATION = 'DISABLE_AUTOMATION';
export const DISABLE_AUTOMATION_SUCCESS = 'DISABLE_AUTOMATION_SUCCESS';
export const DISABLE_AUTOMATION_ERROR = 'DISABLE_AUTOMATION_ERROR';
export const DELETE_AUTOMATION = 'DELETE_AUTOMATION';
export const DELETE_AUTOMATION_SUCCESS = 'DELETE_AUTOMATION_SUCCESS';
export const DELETE_AUTOMATION_ERROR = 'DELETE_AUTOMATION_ERROR';
export const EDIT_AUTOMATION = 'EDIT_AUTOMATION';
export const EDIT_AUTOMATION_DATA = 'EDIT_AUTOMATION_DATA';
export const CLEAR_AUTOMATION_DATA = 'CLEAR_AUTOMATION_DATA';
export const EDIT_AUTOMATION_SUCCESS = 'EDIT_AUTOMATION_SUCCESS';
export const EDIT_AUTOMATION_ERROR = 'EDIT_AUTOMATION_ERROR';
export const GET_EMAILCAMPAIGN = 'GET_EMAILCAMPAIGN';
export const GET_EMAILCAMPAIGN_SUCCESS = 'GET_EMAILCAMPAIGN_SUCCESS';
export const GET_EMAILCAMPAIGN_ERROR = 'GET_EMAILCAMPAIGN_ERROR';
export const CLEAR_EMAILCAMPAIGN_ERROR = 'CLEAR_EMAILCAMPAIGN_ERROR';
export const CLONE_EMAILCAMPAIGN = 'CLONE_EMAILCAMPAIGN';
export const CLONE_EMAILCAMPAIGN_SUCCESS = 'CLONE_EMAILCAMPAIGN_SUCCESS';
export const CLONE_EMAILCAMPAIGN_ERROR = 'CLONE_EMAILCAMPAIGN_ERROR';
export const EXPORT_EMAIL_CAMPAIGN_CONTACTS = 'EXPORT_EMAIL_CAMPAIGN_CONTACTS';
export const EXPORT_EMAIL_CAMPAIGN_CONTACTS_SUCCESS = 'EXPORT_EMAIL_CAMPAIGN_CONTACTS_SUCCESS';
export const EXPORT_EMAIL_CAMPAIGN_CONTACTS_ERROR = 'EXPORT_EMAIL_CAMPAIGN_CONTACTS_ERROR';
export const SEND_CONTACTS_TO_CAMPAIGN = 'SEND_CONTACTS_TO_CAMPAIGN';
export const CLEAR_SEND_TO_CAMPAIGN = 'CLEAR_SEND_TO_CAMPAIGN';
export const SEND_CONTACTS_TO_CAMPAIGN_SUCCESS = 'SEND_CONTACTS_TO_CAMPAIGN_SUCCESS';
export const SEND_CONTACTS_TO_CAMPAIGN_ERROR = 'SEND_CONTACTS_TO_CAMPAIGN_ERROR';
export const SEND_PROSPECTS_TO_CAMPAIGN = 'SEND_PROSPECTS_TO_CAMPAIGN';
export const SEND_PROSPECTS_TO_CAMPAIGN_SUCCESS = 'SEND_PROSPECTS_TO_CAMPAIGN_SUCCESS';
export const SEND_PROSPECTS_TO_CAMPAIGN_ERROR = 'SEND_PROSPECTS_TO_CAMPAIGN_ERROR';
export const SEND_PEOPLE_PROSPECTS_TO_CAMPAIGN = 'SEND_PEOPLE_PROSPECTS_TO_CAMPAIGN';
export const SEND_PEOPLE_PROSPECTS_TO_CAMPAIGN_SUCCESS = 'SEND_PEOPLE_PROSPECTS_TO_CAMPAIGN_SUCCESS';
export const SEND_PEOPLE_PROSPECTS_TO_CAMPAIGN_ERROR = 'SEND_PEOPLE_PROSPECTS_TO_CAMPAIGN_ERROR';
export const RE_TRIGGER_AUTOMATION = 'RE_TRIGGER_AUTOMATION';
export const RE_TRIGGER_AUTOMATION_SUCCESS = 'RE_TRIGGER_AUTOMATION_SUCCESS';
export const RE_TRIGGER_AUTOMATION_ERROR = 'RE_TRIGGER_AUTOMATION_ERROR';

//INTEGRATIONS
export const CREATE_NEW_CONNECTION = 'CREATE_NEW_CONNECTION';
export const CREATE_NEW_CONNECTION_SUCCESS = 'CREATE_NEW_CONNECTION_SUCCESS';
export const CREATE_NEW_CONNECTION_ERROR = 'CREATE_NEW_CONNECTION_ERROR';
export const DISCONNECT_INTEGRATIONS = 'DISCONNECT_INTEGRATIONS';
export const DISCONNECT_INTEGRATIONS_SUCCESS = 'DISCONNECT_INTEGRATIONS_SUCCESS';
export const DISCONNECT_INTEGRATIONS_ERROR = 'DISCONNECT_INTEGRATIONS_ERROR';
export const CLEAR_CREATE_NEW_CONNECTION = 'CLEAR_CREATE_NEW_CONNECTION';
export const GET_EMAIL_ACCOUNTS = 'GET_EMAIL_ACCOUNTS';
export const GET_EMAIL_ACCOUNTS_SUCCESS = 'GET_EMAIL_ACCOUNTS_SUCCESS';
export const GET_EMAIL_ACCOUNTS_ERROR = 'GET_EMAIL_ACCOUNTS_ERROR';
export const GET_EMAIL_ACCOUNTS_STATS = 'GET_EMAIL_ACCOUNTS_STATS';
export const GET_EMAIL_ACCOUNTS_STATS_SUCCESS = 'GET_EMAIL_ACCOUNTS_STATS_SUCCESS';
export const GET_EMAIL_ACCOUNTS_STATS_ERROR = 'GET_EMAIL_ACCOUNTS_STATS_ERROR';
export const DELETE_EMAIL_ACCOUNTS = 'DELETE_EMAIL_ACCOUNTS';
export const DELETE_EMAIL_ACCOUNTS_SUCCESS = 'DELETE_EMAIL_ACCOUNTS_SUCCESS';
export const DELETE_EMAIL_ACCOUNTS_ERROR = 'DELETE_EMAIL_ACCOUNTS_ERROR';
export const FETCH_USER_EMAIL_ACCOUNT = 'FETCH_USER_EMAIL_ACCOUNT';
export const FETCH_USER_EMAIL_ACCOUNT_SUCCESS = 'FETCH_USER_EMAIL_ACCOUNT_SUCCESS';
export const FETCH_USER_EMAIL_ACCOUNT_ERROR = 'FETCH_USER_EMAIL_ACCOUNT_ERROR';
export const SET_USER_EMAIL_ACCOUNT = 'SET_USER_EMAIL_ACCOUNT';
export const SET_USER_EMAIL_ACCOUNT_SUCCESS = 'SET_USER_EMAIL_ACCOUNT_SUCCESS';
export const SET_USER_EMAIL_ACCOUNT_ERROR = 'SET_USER_EMAIL_ACCOUNT_ERROR';
export const GET_EMAIL_PROVIDER_ID = 'GET_EMAIL_PROVIDER_ID';
export const GET_EMAIL_PROVIDER_ID_SUCCESS = 'GET_EMAIL_PROVIDER_ID_SUCCESS';
export const GET_EMAIL_PROVIDER_ID_ERROR = 'GET_EMAIL_PROVIDER_ID_ERROR';

export const FIX_SPAMMED_EMAIL_ACCOUNT = 'FIX_SPAMMED_EMAIL_ACCOUNT';
export const FIX_SPAMMED_EMAIL_ACCOUNT_SUCCESS = 'FIX_SPAMMED_EMAIL_ACCOUNT_SUCCESS';
export const FIX_SPAMMED_EMAIL_ACCOUNT_ERROR = 'FIX_SPAMMED_EMAIL_ACCOUNT_ERROR';

export const CONNECT_IN_HOUSE_INTEGRATION = 'CONNECT_IN_HOUSE_INTEGRATION';
export const CONNECT_IN_HOUSE_INTEGRATION_SUCCESS = 'CONNECT_IN_HOUSE_INTEGRATION_SUCCESS';
export const CONNECT_IN_HOUSE_INTEGRATION_ERROR = 'CONNECT_IN_HOUSE_INTEGRATION_ERROR';

// EMAIL CAMPAIGN TEMPLATE
export const ADD_CAMPAIGN_TEMPLATE = 'ADD_CAMPAIGN_TEMPLATE';
export const CLEAR_CAMPAIGN_TEMPLATE_MESSAGE = 'CLEAR_CAMPAIGN_TEMPLATE_MESSAGE';
export const ADD_CAMPAIGN_TEMPLATE_ERROR = 'ADD_CAMPAIGN_TEMPLATE_SUCCESS';
export const ADD_CAMPAIGN_TEMPLATE_SUCCESS = 'ADD_CAMPAIGN_TEMPLATE_ERROR';

export const EDIT_CAMPAIGN_TEMPLATE = 'EDIT_CAMPAIGN_TEMPLATE';
export const EDIT_CAMPAIGN_TEMPLATE_ERROR = 'EDIT_CAMPAIGN_TEMPLATE_SUCCESS';
export const EDIT_CAMPAIGN_TEMPLATE_SUCCESS = 'EDIT_CAMPAIGN_TEMPLATE_ERROR';

export const DELETE_CAMPAIGN_TEMPLATE = 'DELETE_CAMPAIGN_TEMPLATE';
export const DELETE_CAMPAIGN_TEMPLATE_ERROR = 'DELETE_CAMPAIGN_TEMPLATE_ERROR';
export const DELETE_CAMPAIGN_TEMPLATE_SUCCESS = 'DELETE_CAMPAIGN_TEMPLATE_SUCCESS';
export const ORDER_CAMPAIGN_TEMPLATES = 'ORDER_CAMPAIGN_TEMPLATES';
export const ORDER_CAMPAIGN_TEMPLATES_ERROR = 'ORDER_CAMPAIGN_TEMPLATES_SUCCESS';
export const ORDER_CAMPAIGN_TEMPLATES_SUCCESS = 'ORDER_CAMPAIGN_TEMPLATES_ERROR';

export const GET_CAMPAIGN_TEMPLATE_SUCCESS = 'GET_CAMPAIGN_TEMPLATE_SUCCESS';
export const GET_CAMPAIGN_TEMPLATE_ERROR = 'GET_CAMPAIGN_TEMPLATE_ERROR';
export const GET_CAMPAIGN_TEMPLATE = 'GET_CAMPAIGN_TEMPLATE';
export const PREVIEW_EMAIL_SUCCESS = 'PREVIEW_EMAIL_SUCCESS';
export const PREVIEW_EMAIL_ERROR = 'PREVIEW_EMAIL_ERROR';
export const PREVIEW_EMAIL_REQUEST = 'PREVIEW_EMAIL_REQUEST';

export const GET_EXISTING_EMAIL_CAMPAIGNS_STATS = 'GET_EXISTING_EMAIL_CAMPAIGNS_STATS';
export const GET_EXISTING_EMAIL_CAMPAIGNS_STATS_SUCCESS = 'GET_EXISTING_EMAIL_CAMPAIGNS_STATS_SUCCESS';
export const GET_EXISTING_EMAIL_CAMPAIGNS = 'GET_EXISTING_EMAIL_CAMPAIGNS';
export const GET_EXISTING_EMAIL_CAMPAIGNS_SUCCESS = 'GET_EXISTING_EMAIL_CAMPAIGNS_SUCCESS';
export const GET_EXISTING_EMAIL_CAMPAIGNS_ERROR = 'GET_EXISTING_EMAIL_CAMPAIGNS_ERROR';

export const GET_EMAIL_CAMPAIGNS = 'GET_EMAIL_CAMPAIGNS';
export const GET_EMAIL_CAMPAIGNS_SUCCESS = 'GET_EMAIL_CAMPAIGNS_SUCCESS';
export const GET_EMAIL_CAMPAIGNS_ERROR = 'GET_EMAIL_CAMPAIGNS_ERROR';

export const PUT_EMAIL_CAMPAIGNS = 'PUT_EMAIL_CAMPAIGNS';
export const PUT_EMAIL_CAMPAIGNS_SUCCESS = 'PUT_EMAIL_CAMPAIGNS_SUCCESS';
export const PUT_EMAIL_CAMPAIGNS_ERROR = 'PUT_EMAIL_CAMPAIGNS_ERROR';

export const STOP_EMAIL_CAMPAIGN = 'STOP_EMAIL_CAMPAIGN';
export const STOP_EMAIL_CAMPAIGN_SUCCESS = 'STOP_EMAIL_CAMPAIGN_SUCCESS';
export const STOP_EMAIL_CAMPAIGN_ERROR = 'STOP_EMAIL_CAMPAIGN_ERROR';

export const UPDATE_ACTIVE_SWITCH = 'UPDATE_ACTIVE_SWITCH';
export const UPDATE_ACTIVE_SWITCH_SUCCESS = 'UPDATE_ACTIVE_SWITCH_SUCCESS';
export const UPDATE_ACTIVE_SWITCH_ERROR = 'UPDATE_ACTIVE_SWITCH_ERROR';
export const DELETE_EMAIL_CAMPAIGN = 'DELETE_EMAIL_CAMPAIGN';
export const DELETE_EMAIL_CAMPAIGN_SUCCESS = 'DELETE_EMAIL_CAMPAIGN_SUCCESS';
export const DELETE_EMAIL_CAMPAIGN_ERROR = 'DELETE_EMAIL_CAMPAIGN_ERROR';
export const TEST_EMAIL_SETTINGS = 'TEST_EMAIL_SETTINGS';
export const TEST_EMAIL_SETTINGS_SUCCESS = 'TEST_EMAIL_SETTINGS_SUCCESS';
export const TEST_EMAIL_SETTINGS_ERROR = 'TEST_EMAIL_SETTINGS_ERROR';
export const CREATE_NEW_CAMPAIGN = 'CREATE_NEW_CAMPAIGN';
export const CREATE_NEW_CAMPAIGN_SUCCESS = 'CREATE_NEW_CAMPAIGN_SUCCESS';
export const CREATE_NEW_CAMPAIGN_ERROR = 'CREATE_NEW_CAMPAIGN_ERROR';

export const CONNECT_CONTACTS_CAMPAIGN = 'CONNECT_CONTACTS_CAMPAIGN';
export const CONNECT_CONTACTS_CAMPAIGN_SUCCESS = 'CONNECT_EMAIL_CAMPAIGN_SUCCESS';
export const CONNECT_CONTACTS_CAMPAIGN_ERROR = 'CONNECT_EMAIL_CAMPAIGN_ERROR';

export const CONNECT_GTM_CONTAINERS = 'CONNECT_GTM_CONTAINERS';
export const CONNECT_GTM_CONTAINERS_SUCCESS = 'CONNECT_GTM_CONTAINERS_SUCCESS';
export const CONNECT_GTM_CONTAINERS_ERROR = 'CONNECT_GTM_CONTAINERS_ERROR';
export const CLEAR_GTM_CONTAINER = 'CLEAR_GTM_CONTAINER';

// WELCOME PAGE
export const SET_PROGRESS = 'SET_PROGRESS';
export const SET_PROGRESS_SUCCESS = 'SET_PROGRESS_SUCCESS';

// SELECTED ROW IDS
export const SET_SELECTED_ROW_IDS = 'SET_SELECTED_ROW_IDS';
export const SET_ALL_PAGES_SELECTED = 'SET_ALL_PAGES_SELECTED';
export const SET_ALL_PROSPECTS_PAGES_SELECTED = 'SET_ALL_PROSPECTS_PAGES_SELECTED';

export const RESET_LEAD_SCORES = 'RESET_LEAD_SCORES';
export const RESET_LEAD_SCORES_SUCCESS = 'RESET_LEAD_SCORES_SUCCESS';
export const RESET_LEAD_SCORES_ERROR = 'RESET_LEAD_SCORES_ERROR';
export const CLEAR_RESET_LEAD_SCORES = 'CLEAR_RESET_LEAD_SCORES';
